<template>
<div id="page-cortes-generales">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Consulta Corte General de Caja" class="elevation-1 px-5 py-3">
          
          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                
                <template slot="fecha" slot-scope="props">
                  {{parse_date_time(props.row.fecha)}}
                </template>
                
                <template slot="efectivo_retirado" slot-scope="props">
                    <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.efectivo_retirado).toFixed(2))}}</div>
                </template>
                <template slot="monto_voucher" slot-scope="props">
                    <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_voucher).toFixed(2))}}</div>
                </template>
                <template slot="monto_transferencia" slot-scope="props">
                    <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_transferencia).toFixed(2))}}</div>                    
                </template>
                <template slot="monto_cheques" slot-scope="props">
                    <div v-if="props.row.monto_cheques" style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_cheques).toFixed(2))}}</div>
                    <div v-else style="text-align:right !important;">$0.00</div>
                </template>
                <template slot="monto_total" slot-scope="props">
                    <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_total).toFixed(2))}}</div>
                </template>
                <template slot="faltante" slot-scope="props">
                    <div v-if="props.row.faltante" style="text-align:right !important;">
                      ${{ formatNumber(parseFloat(props.row.faltante).toFixed(2))}}
                    </div>
                </template>
                <template slot="sobrante" slot-scope="props">
                    <div v-if="props.row.sobrante" style="text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.sobrante).toFixed(2))}}
                      </div>
                </template>
                <template slot="monto_total_final" slot-scope="props">
                    <div style="text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.monto_total_final).toFixed(2))}}
                    </div>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-show="props.row.tipo == 'corte_general'"
                    v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" 
                      @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>                    
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Actualizar Datos</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
                <v-col>
                    EMPLEADO: <strong>{{model.cajero}}</strong>
                </v-col>
                <v-col>
                    FECHA: <strong>{{parse_date(model.fecha)}}</strong>
                </v-col>
            </v-row><br>
            <v-row >
              <v-col sm="4" md="4" lg="4" >
                <v-text-field label="$ A Retirar" v-model="model.monto_total" readonly disabled></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4" >
                <v-text-field label="$ Faltante" v-model="model.faltante" :rules="[validaCantidadFloatCero]"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4" >
                <v-text-field label="$ Sobrante" v-model="model.sobrante" :rules="[validaCantidadFloatCero]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col sm="12" md="12" lg="12" style="text-align:center !important;">
                <v-text-field label="Observaciones" v-model="model.observaciones"></v-text-field>
              </v-col>
            </v-row>    

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.cortes.empleado.resumen')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  created: function() {
    
  },
  data() {
    return {
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      name: "datagrid",
      columns: ["caja",
        "cajero","sucursal.nombre","fecha","supervisor.nombre","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","monto_total","faltante","sobrante","monto_total_final","observaciones",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["caja","cajero","sucursal.nombre","fecha","supervisor.nombre","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","monto_total","faltante","sobrante","observaciones"],
        sortable: ["caja","cajero","sucursal.nombre","fecha","supervisor.nombre","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","monto_total","faltante","sobrante","observaciones"],
        pagination: {
          infiniteHandler: this.infiniteHandler
        },
        headings: {
          "supervisor.nombre":"Retiró",
          "sucursal.nombre":"Sucursal",
          "efectivo_retirado":"Efectivo Retirado",
          "monto_voucher":"Voucher Retirado",
          "monto_transferencia":"Transferencias",
          "monto_cheques":"Cheques",
          "monto_total":"Total Retirado",
          "monto_total_final": "Monto Total",
          "actions":"Operaciones"          
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "Consulta Corte General de Caja",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "corte_caja",
        "tipo": {"$exists":true},
        "fecha": {"$exists":true}
      };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if (x == "efectivo_retirado") {
             //where[x] = parseFloat(this.model_filters[x])
            where["$or"] = [
                {"efectivo_retirado": parseFloat(this.model_filters[x])}, {"efectivo_retirado": {"$regex": "(?i)" + this.model_filters[x]}}
              ]
          }else if (x == "monto_voucher") {             
            where["$or"] = [
                {"monto_voucher": parseFloat(this.model_filters[x])}, {"monto_voucher": {"$regex": "(?i)" + this.model_filters[x]}}
              ]          
          } else if (x == "monto_transferencia") {             
            where["$or"] = [
                {"monto_transferencia": parseFloat(this.model_filters[x])}, {"monto_transferencia": {"$regex": "(?i)" + this.model_filters[x]}}
              ]          
          } else if (x == "monto_cheques") {             
            where["$or"] = [
                {"monto_cheques": parseFloat(this.model_filters[x])}, {"monto_cheques": {"$regex": "(?i)" + this.model_filters[x]}}
              ]                    
          } else if (x == "monto_total") {             
            where["$or"] = [
                {"monto_total": parseFloat(this.model_filters[x])}, {"monto_total": {"$regex": "(?i)" + this.model_filters[x]}}
              ]          
          } else if (x == "faltante") {             
            where["$or"] = [
                {"faltante": parseFloat(this.model_filters[x])}, {"faltante": {"$regex": "(?i)" + this.model_filters[x]}}
              ]          
          } else if (x == "sobrante") {             
            where["$or"] = [
                {"sobrante": parseFloat(this.model_filters[x])}, {"sobrante": {"$regex": "(?i)" + this.model_filters[x]}}
              ]          
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"type":"desc"},{"tipo":"desc"},{"fecha":"desc"}],
            use_index: "_design/8cc053cf114b6360314f06a6097f8079fa817542"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            for(var k in nuevos){
              nuevos[k]["monto_total_final"] = parseFloat(nuevos[k]["monto_total"]);
              if(nuevos[k]["faltante"]){
                nuevos[k]["monto_total_final"] -= parseFloat(nuevos[k]["faltante"]);
              }
              if(nuevos[k]["sobrante"]){
                nuevos[k]["monto_total_final"] += parseFloat(nuevos[k]["sobrante"]);
              }
            }
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    validaCantidadFloatCero: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return true;
        }
        return true;
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    openModal: function(type) {
      if (type === 1) {
        this.update = false;        
      } else {
        this.update = true;
      }

      this.modal = true;
    },

    getRegistro: function(id_registro) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          this.model = response.data;
          this.openModal(2);
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    
    updateRegistro: function() {

      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        
          let data = this.model;

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
      }
    },
    deleteRegistro: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
            }).then(() => {
              window.dialogLoader.hide();
            });

        }

      });
    },

  }
};
</script>
